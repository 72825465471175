@import '../../../../../../sass/main.scss';

.examDiscussionDetailBody {
  display: flex;
  gap: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 3.4rem;

  &__info {
    width: 100%;
    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4.5rem;

      h4 {
        font-weight: 600;
        font-size: 1.4rem;
        color: #111111;
      }

      p {
        font-weight: normal;
        font-size: 1.5rem;
        color: #333333;
      }
    }

    p {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #333333;
    }

    &--imagesContainer {
      margin-top: 2.4rem;
      display: grid;
      gap: 2%;
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
      img {
        width: 100%;
        max-height: 19.4rem;
        object-fit: cover;
      }
    }
  }
}
