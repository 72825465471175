@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Righteous&family=Roboto:wght@400;500;700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // font-size: 1.0rem; //youtube uses fixed font-size
  font-size: 62.5%; //this defines what 1rem is
  scroll-behavior: smooth;
}

body {
  // font-family: 'Manrope', sans-serif;
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(243, 244, 246, 0.5);
  letter-spacing: 0.01rem;
}

#root {
  // background-color: $color-white-3;
  height: 100%;
}

a {
  text-decoration: none !important;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
}

// custom scrollbar
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 0.5rem;
}

.mord.mtight {
  color: #111111;
}

.MJX-TEX {
  // font-family: 'Poppins' !important;
  font-family: 'Poppins' !important;
}
