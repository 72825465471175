@import '../../../../sass/main.scss';

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 2rem;
  position: relative;

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.001em;
    color: #111111;
    text-transform: capitalize;
  }

  &__filterBox {
    display: flex;
    gap: 2rem;
    @include customMaxBreakPoint(750) {
      flex-direction: column;
    }
  }

  &__dropdownSection {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.4px;
      color: #999999;
      white-space: nowrap;
    }
    &.type {
      justify-content: flex-end;
    }
  }
  @include customMaxBreakPoint(450) {
    flex-direction: column;
    &__dropdownSection {
      flex-direction: column;
      gap: 0.4rem;
      width: 50%;

      label {
        float: right;
        margin-right: auto;
      }
      &--select {
        width: 100%;
      }
    }
    &__filterBox {
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
      width: 100%;
    }
  }
}
