.RecentlyRelease {
  margin-top: 2rem;
  border-radius: 6px 6px 0px 0px;
  background: white;
  width: 100%;
  padding: 2rem;
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 1em;
    margin-top: 1.4rem;
  }
}
