.submitfeedbackheader {
  h5 {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: center;
    color: #999999;
  }
}
