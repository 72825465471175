@import '../../../sass/main.scss';

.profile {
  &__img {
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @include customMaxBreakPoint(600) {
    display: none;
  }
}
