@import "../../../../../sass/main.scss";

.examDiscussionTable {
  width: 100%;
  border-collapse: collapse;

  // @include customMaxBreakPoint(900) {
  //   overflow-x: auto;
  // }

  thead {
    background: #ebf6f3;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 3px 3px 0px 0px;
    font-size: 1.4rem;
    letter-spacing: 0.05em;
    color: #333333;
    text-transform: capitalize;
  }
  thead > tr > th {
    padding: 1.3rem 1rem 1.3rem 2.4rem;
    text-align: left;
  }

  tbody {
    font-size: 1.5rem;
    color: #111111;
    font-weight: normal;
    background-color: #fff;
  }
  tbody > tr {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
  }
  tbody > tr > td {
    padding: 1.3rem 1rem 1.3rem 2.4rem;
    text-align: left;
    color: #111111;
  }

  &__td {
    &--forum {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      img {
        width: 5.6rem;
        height: 4.8rem;
      }

      &-info {
        a {
          color: #111111;
        }
        h5 {
          font-size: 1.5rem;
          font-weight: normal;
          transition: 100ms;
          cursor: pointer;
          &:hover {
            color: #4bbd97;
          }
        }

        p {
          font-size: 1.3rem;
          line-height: 1.8rem;
          color: #4bbd97;
          margin-top: 1rem;
          text-transform: capitalize;
        }
      }
      @include customMaxBreakPoint(600) {
        &--info {
          width: 20rem;
        }
      }
    }
    &--activity {
      h5,
      p {
        white-space: nowrap;
      }

      h5 {
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 2rem;
        color: #111111;
      }

      p {
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: #4bbd97;
        margin-top: 1rem;
        text-transform: capitalize;
      }
    }
  }
}
