.mySaveExamCardSection {
  margin-top: 4rem;
  margin-bottom: 6rem;

  &__cardsBody {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    column-gap: 2.6rem;
    row-gap: 2.4rem;
  }

  &__loadMoreSection {
    display: flex;
    justify-content: center;
    button {
      border: 1px solid #4bbd97;
      box-sizing: border-box;
      border-radius: 5px;
      height: 4rem;
      padding: 0 2.4rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: transparent;
    }
  }
}
