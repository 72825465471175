@import '../../../../../../sass/main.scss';

.sharereplyreport {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  column-gap: 3rem;

  @include customMaxBreakPoint(650) {
    flex-direction: column;
    row-gap: 1rem;
  }

  button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    text-transform: capitalize;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: #333333;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 1.2rem;
    height: 3.8rem;
    // background-color: red;
  }

  &__left {
    display: flex;
    gap: 1rem;
    align-items: center;

    &--greyBtn {
      background-color: rgba(219, 213, 213, 0.4) !important;
    }
    &--cyanBtn {
      background: #4bbd97 !important;
      color: #fff !important;
    }
  }

  &__right {
    display: flex;
    gap: 1.6rem;
    align-items: center;
  }
}
