@import '../../sass/main.scss';

.input {
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  color: $color-black-2;
  &::placeholder {
    color: #999999;
    text-transform: capitalize;
  }
}

.input.input--sm {
  height: 3rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0.4rem 0.9rem;

  &::placeholder {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

.input.input--md {
  height: 4rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 0.6rem 1.4rem;
  &::placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
