.shareHover {
  padding: 2.4rem;
  width: 34rem;
  background-color: #fff;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #111827;
    }

    svg {
      color: #9ca3af;
      cursor: pointer;
      transition: 350ms;
      font-size: 1.2rem;

      &:hover {
        color: #111827;
      }
    }
  }

  &__body {
    margin-top: 1.7rem;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border: 1px solid #dfe0eb;
      box-sizing: border-box;
      border-radius: 4px 0px 0px 4px;
      height: 3rem;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #6b7280;
      padding: 0.4rem 0.9rem;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.4rem;
      height: 3rem;
      cursor: pointer;
      transition: 350ms;
      box-sizing: border-box;
      border-radius: 0px 4px 4px 0px;
    }

    &--checkIcon {
      background: #10b981;
      svg {
        color: #fff;
        font-size: 1.5rem;
      }
    }

    &--regCopyIcon {
      background-color: #111827;
      svg {
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }

  &__icons {
    display: flex;
    margin-top: 1.7rem;
    gap: 0.8rem;

    button {
      background-color: #e5e7eb !important;
      border-radius: 8px !important;
      padding: 0.7rem 1rem !important;

      svg {
        font-size: 2rem;
      }
    }
  }
}
