@import '../../../../../../sass/main.scss';

.pastExamCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 3rem 0;

  &__left {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    img {
      height: 8rem;
      width: 9rem;
    }

    &--content {
      h5 {
        font-weight: 600;
        font-size: 1.6rem;
        letter-spacing: 0.001em;
        color: #232a31;
        margin-bottom: 0.2rem;
        @include limitNoOfLines(2);
      }

      &-totalStudents {
        display: flex;
        gap: 1.2rem;
        align-items: center;
        // margin-bottom: 0.7rem;

        img {
          width: 1.2rem;
          height: 1.4rem;
        }

        span {
          font-size: 1.4rem;
          color: #333333;
        }
      }
      &-lastplayed {
        display: flex;
        gap: 2px;
        align-items: center;

        h4 {
          font-weight: 600;
          font-size: 1.4rem;
          color: #232a31;
        }
        p {
          font-size: 1.4rem;
          color: #232a31;
        }
      }
      &-info {
        display: flex;
        gap: 1rem;
        align-items: center;
        // flex-wrap: wrap;

        div {
          display: flex;
          gap: 2px;
          align-items: center;
          // flex-wrap: wrap;

          h4 {
            font-weight: 600;
            font-size: 1.4rem;
            color: #232a31;
          }
          p {
            font-size: 1.4rem;
            color: #232a31;
          }
          .correct-ans {
            color: green;
          }
          .incorrect-ans {
            color: red;
          }
          .unattendent-ans {
            color: rgb(184, 184, 9);
          }
        }
      }
      &-time {
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.9rem;
        display: flex;
        align-items: center;
        color: #999999;
      }
    }
  }
  @include customMaxBreakPoint(720) {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      width: 100%;
    }
  }
  @include customMaxBreakPoint(500) {
    flex-direction: column;

    &__left {
      &--content {
        &-info {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
        }
      }
    }
  }
}
