.success-route {
  width: 100%;
  height: 15rem;
  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #3c763d;
    background: #ebf6f3;
    border-radius: 4px;
    padding: 1rem 1.2rem;
  }

  a {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #111111;
  }
}

.changepassword {
  width: 100%;

  h1 {
    text-align: center;
    margin-bottom: 2.4rem;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    color: #000000;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #3c763d;
    background: #ebf6f3;
    border-radius: 4px;
    padding: 1rem 1.2rem;
    margin-top: 1.6rem;
  }
}

.changepasswordform {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
