@import '../../../../sass/main.scss';

.questionsLeft {
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;

  h4 {
    font-weight: 600;
    font-size: 18px;
    color: #111111;
    padding: 1.8rem 2rem;
    background: #f4f4f4;
  }

  &__flex {
    display: flex;
    padding: 2.4rem 1rem 3.3rem 1rem;
    gap: 1rem;
    justify-content: space-between;

    > * {
      text-align: center;
      span {
        font-weight: bold;
        font-size: 2rem;
      }

      p {
        font-size: 1.6rem;
        color: #999999;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }

    &--answered {
      span {
        color: #4bbd97;
      }
    }

    &--notanswered {
      span {
        color: #ff647c;
      }
    }
    &--notvisited {
      span {
        color: #ffb800;
      }
    }
  }
}
