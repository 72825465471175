.emptyPurchaseList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;

  img {
    width: 9.5rem;
    height: 9.5rem;
  }

  h5 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #232a31;
    margin: 1rem 0 0.8rem 0;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: center;
    letter-spacing: 0.0025em;
    color: #5b636a;
    margin-bottom: 2.4rem;
  }
}
