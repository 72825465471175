button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-body {
  margin-top: 8rem;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  .app-body {
    margin-top: 6.6rem;
  }
}

.validation__error {
  color: #ff3333;
  font-size: 14px;
  margin: 4px 0;
}
