.courseDetailsTitle {
  p,
  h1 {
    text-align: center;
  }

  p {
    font-weight: normal;
    font-size: 1.8rem;
    color: #999999;
    margin-bottom: 1.2rem;
  }

  h1 {
    font-weight: bold;
    font-size: clamp(2.4rem, 5vw, 3rem);
    color: #333333;
  }
}
