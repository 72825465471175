.react-pagination {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  margin-top: 4rem;

  li {
    list-style-type: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #dfe0eb;
    box-sizing: border-box;
    border-radius: 4px;
    transition: 350ms;

    &:hover {
      border: 1px solid #4bbd97;
    }
  }

  .pagination-prev-link,
  .pagination-next-link,
  .pagination-page-link {
    font-size: 1.4rem;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #999999;
      font-size: 1.2rem;
    }
  }
  .pagination-next,
  .pagination-previous {
    &.pagination-disabled {
      background: #dfe0eb;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .pagination-active {
    border: 1px solid #4bbd97;
    color: #4bbd97;
  }
}
