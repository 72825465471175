.loginform {
  width: 100%;

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    color: #000000;
  }

  &__form {
    margin-top: 2.4rem;
    margin-bottom: 1.2rem;

    &--forgotPassword {
      text-align: right;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #18a0fb;
      margin-top: 0.8rem;
      margin-bottom: 3rem;
    }
  }

  &__terms {
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #000000;

    span {
      text-decoration-line: underline;
      color: #4bbd97;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    color: #000000;
    margin-top: 2rem;

    span {
      font-weight: 600;
    }
  }
}
