.submitModal {
  &__cancelbtn {
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--icon {
      background: rgba(255, 184, 0, 0.1);
      border-radius: 10px;
      width: 6rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #ffb800;
        font-size: 2rem;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.0015em;
      color: #232a31;
      max-width: 32rem;
      margin-top: 1rem;
      margin-bottom: 0.8rem;
    }

    p {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.0025em;
      color: #5b636a;
    }

    &--btnGroups {
      margin-top: 2.4rem;
      display: flex;
      gap: 2.4rem;
      margin-bottom: 2rem;

      &-btn {
        width: 10rem;
        height: 4rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}
