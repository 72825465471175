@import '../../../../sass/main.scss';

.leaderboardCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__imageContainer {
    border: 6px solid $color-cyan-1;
    background-color: $color-cyan-1;
    box-sizing: border-box;
    border-radius: 50%;
    width: 17rem;
    height: 17rem;
    overflow: hidden;
    margin-bottom: 1.4rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
    color: #333333;
    margin-bottom: 0.6rem;
    text-transform: capitalize;
  }

  &__score {
    background: $color-cyan-1;
    padding: 0.4em 0.9em;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.9rem;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    border-radius: 12rem;
  }
}
