@import '../../sass/main.scss';

.courseCard {
  width: 100%;

  &__image {
    width: 100%;

    img {
      aspect-ratio: 16/9;
      width: 100%;
    }
  }

  &__info {
    margin-top: 1.2rem;
    h5 {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      @include limitNoOfLines(2);
    }

    p {
      font-size: 13px;
      color: #7a7a7a;
      margin-top: 0.1rem;
    }
  }
}
