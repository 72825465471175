.pocketExam__category {
  display: flex;
  cursor: pointer;
}
.pocketExam__category > button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background-color: white;
}
.pocketExam__category > button > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
}
