.select-dropdown {
  padding: 0.4rem 0.8rem;
  text-transform: capitalize;
  cursor: pointer;
  outline: none;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 600;
  min-width: 10rem;
}

.select--sm {
  font-size: 15px;
  line-height: 20px;
  height: 3.6rem;
}

.select--md {
  font-size: 16px;
  line-height: 22px;
  height: 4rem;
}
