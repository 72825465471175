.benefitsForOurLearners {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-blend-mode: screen;
  /* clip-path: ellipse(78% 100% at 50% 0%); */
  background: #ebf6f3;
  padding-bottom: 80px;
}

.benefitsForOurLearners > p {
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #999999;
  margin-bottom: 20px;
  z-index: 1;
  background: #ebf6f3;
}

.benefitsForOurLearners > h4 {
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #333333;
  margin-bottom: 40px;
  z-index: 1;
  background: #ebf6f3;
}
