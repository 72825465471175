@import '../../../../../../../sass/main.scss';

.newTopicDetails {
  width: 40%;
  @include customMaxBreakPoint(1024) {
    width: 40%;
  }
  @include customMaxBreakPoint(768) {
    width: 100%;
  }
  p {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111111;
  }

  &__list {
    margin: 1rem 0;
    li {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #111111;
    }
  }
}
