.mySaveExamRecommend {
  margin-top: 10px;

  &__cardContainer {
    margin-top: 1.4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    gap: 2rem;
  }
}
