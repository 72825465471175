@import '../../../../../sass/main.scss';

.rightContent {
  width: 32.6%;
  position: -webkit-sticky;
  top: 0;
  @include customMaxBreakPoint(900) {
    width: 40%;
  }
  @include customMaxBreakPoint(830) {
    display: none;
  }
}
