@import '../../../../sass/main.scss';

.categoryModal {
  max-height: 70vh;
  &__header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    &--title {
      width: 90%;
      h5 {
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #111827;
        text-align: center;
      }
      p {
        margin-top: 0.6rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 0.004em;
        color: #999999;
        text-align: center;
      }
    }
    &--crossIcon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      border: none;
      cursor: pointer;
      font-size: 1.8rem;
      background-color: transparent;
      color: #9ca3af;
      transition: 350ms;
      &:hover {
        color: $color-black-1;
      }
    }
  }

  &__body {
    padding: 0 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    gap: 2rem;
    overflow: auto;

    @include customMaxBreakPoint(600) {
      margin-left: 0;
      margin-right: 0;
    }

    &--card {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      padding: 2rem 1.4rem;
      display: grid;
      justify-items: center;
      height: 16rem;
      cursor: pointer;
      transition: 350ms;

      &:hover {
        transform: scale(1.05);
      }

      img {
        height: 4.4rem;
        margin-bottom: 1rem;
      }

      &-title {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        letter-spacing: 0.001em;
        color: $color-black-1;
        margin-bottom: 0.3rem;
      }
      &-totalStudents {
        font-size: 12px;
        line-height: 16px;
        color: #7a7a7a;
      }
      &-time {
        color: #999999;
      }
    }
  }
}
