.attemptExam {
  margin-top: 2rem;
  border-radius: 6px 6px 0px 0px;
  background: url('../../../../../../assets/backgroundImages/attemptExambackground.svg')
    white;
  width: 100%;
  padding: 1.2rem 2rem;

  &__quizTime {
    margin-top: 4%;
    text-align: center;
    img {
      width: 150px;
      height: 100px;
    }
    p {
      margin: 0;
      padding: 0;
      color: #232a31;
      margin-top: 0.5rem;
      font-size: 1.8rem;
      font-weight: bold;
    }

    &--minusQuestions {
      display: flex;
      justify-content: center;
      margin-top: 0.4rem;
      margin-bottom: 2rem;
      color: #999999;
      gap: 0.6rem;
      font-size: 1.4rem;
    }

    &--btn {
      padding: 0 1.6rem;
      height: 3.4rem;
    }
  }
}
