.advertisementsection {
  background-color: #ffffff;
  padding: 2rem 2rem;
  border-radius: 0 0 6px 6px;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &__container {
    width: 100%;
    aspect-ratio: 16/8;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
