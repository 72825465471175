@import '../../../../../sass/main.scss';

.headerContainer {
  display: flex;
  justify-content: space-between;

  &__textWrapper {
    font-weight: 600;
    h4 {
      font-size: 16px;
      letter-spacing: 0.004em;
      color: #111111;
    }

    p {
      font-size: 12px;
      letter-spacing: 0.004em;
      color: #999999;
      margin-top: 0.2rem;
    }
  }

  &__btnWrapper {
    button {
      border: none;
      background-color: transparent;
      font-weight: 600;
      font-size: 14px;
      color: #151522;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.9rem;

      svg {
        color: $color-cyan-1;
      }
    }
  }
}
