.purchaseHistoryTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 9px;
  margin-top: 4rem;

  thead {
    background: rgba(153, 153, 153, 0.1);
    border-radius: 9px 9px 0px 0px;

    tr > * {
      text-align: start;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.01em;
      color: #7a7a7a;
      padding: 1.6rem 2rem;
    }
  }

  tbody {
    background-color: transparent;

    tr > * {
      padding: 1rem 2rem;
      border-bottom: 1px solid #e1e1e1;
      &:first-child {
        width: 50%;
      }

      svg {
        font-size: 2rem;
        color: #999999;
        cursor: pointer;
        transition: 350ms;

        &:hover {
          color: #111111;
        }
      }
    }
  }
  &__purchaseCol {
    h4 {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #111111;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #7a7a7a;
    }
  }

  &__amountCol {
    p {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      text-transform: uppercase;
      color: #7a7a7a;
    }

    strong {
      color: #111111;
    }
  }

  &__purchaseDateCol {
    p {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #7a7a7a;
    }
  }
}
