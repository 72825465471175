.timeexpiremodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  svg {
    font-size: 5rem;
  }

  h1 {
    margin-top: 2rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #111111;
  }

  p {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.0025em;
    color: #999999;
  }

  &__btn {
    margin-top: 2rem;
  }
}
