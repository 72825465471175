@import '../../../sass/main.scss';

.sidebar {
  // width: 30rem;
  width: 25%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0.6rem;
  position: fixed;
  transition: 350ms;
  position: -webkit-sticky;
  position: sticky;
  top: 10rem;
  max-height: 60rem;

  ul {
    list-style-type: none;
    font-size: 1.4rem;
    padding-left: 2rem;
  }
  a.active {
    background-color: red !important;
  }

  li {
    // padding: 1.4rem 2.4rem;
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #7a7a7a;
    cursor: pointer;
    transition: 350ms;
    text-transform: capitalize;

    &.active {
      color: $color-cyan-1;
    }

    &:hover {
      color: $color-cyan-1;
    }
    svg {
      font-size: 2rem;
    }

    p {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 1.4rem;
    }
  }

  @include customMaxBreakPoint(1024) {
    width: 10rem;

    ul {
      padding: 0;
    }

    li {
      padding: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      p {
        margin: 0;
        text-align: center;
      }
    }
  }
  @include customMaxBreakPoint(600) {
    display: none;
  }
}
