.memberCard {
  display: flex;
  gap: 1.1rem;
  align-items: center;

  &__details {
    h5 {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.001em;
      color: #111111;
      margin-bottom: 0.2rem;
    }

    p {
      font-weight: normal;
      font-size: 1.4rem;
      color: #9299a1;
    }
  }
}
