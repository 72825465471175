@import "../../../sass/main.scss";

.registerform {
  width: 100%;

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    color: #000000;
    text-align: center;
  }

  &__form {
    margin-top: 2.4rem;
    margin-bottom: 1.2rem;
    display: grid;
    gap: 1.4rem;

    &--flex {
      display: flex;
      gap: 1rem;

      @include customMaxBreakPoint(450) {
        flex-direction: column;
        gap: 1.4rem;
      }
    }
  }

  p {
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.5rem;
    // color: #000000;

    span {
      text-decoration-line: underline;
      color: #4bbd97;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    color: #000000;
    margin-top: 2rem;

    span {
      font-weight: 600;
    }
  }
}
