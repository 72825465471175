.performance {
  margin-top: 20px;
  border-radius: 6px;
  background: white;
  padding: 2rem;
  &__body {
    background-color: white;
    margin-top: 1.4rem;
    display: flex;
    justify-content: center;
    gap: 5rem;
    &--score {
      text-align: center;
      h5 {
        font-weight: bold;
        font-size: 2rem;
        color: #4bbd97;
      }

      p {
        font-weight: normal;
        font-size: 1.4rem;
        color: #9299a1;
        margin-top: 0.1rem;
      }
    }

    &--rank {
      text-align: center;
      h5 {
        font-weight: bold;
        font-size: 2rem;
        color: #ff647c;
      }
      p {
        font-weight: normal;
        font-size: 1.4rem;
        color: #9299a1;
        margin-top: 0.1rem;
      }
    }
  }
}
