@import '../../../../../../../sass/main.scss';

.leaderboardModal {
  max-height: 70vh;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    gap: 2rem;
  }

  &__body {
    overflow: auto;
  }
}
