@import '../../sass/main.scss';

.courseExamPage {
  padding-top: 1.6rem;

  &__body {
    margin-top: 2.4rem;
    display: flex;
    gap: 2rem;

    &--left {
      width: 100%;
    }

    &--right {
      position: sticky;
      // top: 14.2rem;
      top: 8rem;
      // height: 42rem;
      height: 100%;
      min-width: 30rem;
      align-items: center;
    }

    @include customMaxBreakPoint(768) {
      &--right {
        display: none;
      }
    }
  }
}
