@import '../../../../../../sass/main.scss';

.leaderboard {
  margin-top: 2rem;
  border-radius: 6px;
  background: white;
  width: 100%;

  &__container {
    padding: 2rem 2rem 0 2rem;
  }
  &__body {
    margin-top: 1.4rem;
  }

  &__btn {
    background: rgba(75, 189, 151, 0.1);
    border-radius: 0px 0px 6px 6px;
    width: 100%;
    height: 5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.9rem;
    font-size: 1.4rem;
    color: #151522;

    svg {
      color: $color-cyan-1;
    }
  }
}
