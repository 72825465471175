.textarea {
  font-weight: normal;
  letter-spacing: 0.06rem;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  color: #111827;

  &::placeholder {
    color: #999999;
  }
}

.textarea.textarea--sm {
  height: 10rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0.4rem 0.9rem;

  &::placeholder {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

.textarea.textarea--md {
  height: 16rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 0.6rem 1.4rem;
  &::placeholder {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
