@import '../../../../../../../../sass/main.scss';

.replyFormModal {
  overflow: hidden;
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3rem;
    &--flex {
      display: flex;
      align-items: flex-start;
      gap: 0.8rem;
      margin-bottom: 2.7rem;

      img {
        margin-top: 0.5rem;
      }

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &__editor {
    width: 70%;
    @include customMaxBreakPoint(1024) {
      width: 100%;
    }
  }

  &__btnGroups {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.4rem;
    margin-top: 2rem;
    &--cancelBtn,
    &--replyBtn {
      text-transform: capitalize;
    }
  }
}
.ck-editor__editable_inline {
  min-height: 20rem;
  padding-left: 30px;
  font-size: 1.6rem;
  @include customMaxBreakPoint(500) {
    min-height: 40vh;
  }
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  width: 100%;
  border-radius: 0 0 10px 10px;
  font-size: 1.6rem;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none;
  border: 1px solid $color-cyan-1;
  border-radius: 0 0 10px 10px;
}
