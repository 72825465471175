@import '../../../../../../sass/main.scss';

.followAcademy {
  margin-top: 2rem;
  border-radius: 6px;
  background: white;
  padding: 2rem;

  &__heading {
    font-weight: 600;
    font-size: 1.6rem;
    text-align: center;
    letter-spacing: 0.001em;
    color: #232a31;
  }

  &__body {
    margin-top: 2.4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
    gap: 0.5rem;

    button {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      background-color: transparent;
      width: 100%;
      padding: 0.85rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 350ms;

      &:hover {
        background-color: $color-grey-light-3;
      }
    }
    &--content {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.004em;

      img {
        width: 2rem;
      }

      &-facebook {
        color: #2196f3;
      }

      &-twitter {
        color: #1da1f2;
      }

      &-instagram {
        color: #c32070;
      }
    }
  }
}
