.iomSuperStartCard {
  width: 100%;
  position: relative;
  aspect-ratio: 16/9;
  // height: 15rem;
  background: linear-gradient(0deg, #ffe6fb, #ffe6fb);
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  &__userInfo {
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5 {
      font-weight: 600;
      font-size: 1.4rem;
      color: #333333;
    }

    p {
      font-size: 1.2rem;
      color: #999999;
      margin-top: 0.3rem;
    }
  }

  &__image {
    display: flex;
    align-self: flex-end;
    img {
      height: 13.4rem;
    }
  }
}
