.avatar {
  // width: 5rem !important;
  // height: 5rem !important;
  font-size: 2rem !important;
}

.avatar.avatar--sm {
  width: 3rem;
  height: 3rem;
}

.avatar.avatar--md {
  width: 4rem;
  height: 4rem;
}

.avatar.avatar--md-l {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar.avatar--lg {
  width: 5rem;
  height: 5rem;
}

.avatar.avatar--xl {
  width: 6.5rem;
  height: 6.5rem;
}
