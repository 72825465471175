@import '../../sass/main.scss';

.examCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.03);
  border-radius: 8px;

  &__header {
    display: flex;
    padding: 2rem 2rem 1rem 2rem;
    gap: 1rem;
    img {
      width: 4.5rem;
      height: 4.5rem;
      background: rgba(75, 189, 151, 0.1);
      border-radius: 7px;
      margin-top: 0.5rem;
    }

    &--info {
      h4 {
        font-weight: 600;
        font-size: 1.6rem;
        color: #111111;
        @include limitNoOfLines(2);
      }
    }
  }

  &__body {
    padding: 0rem 2rem 2rem 2rem;

    &--item {
      display: flex;
      align-items: flex-start;
      gap: 1.7rem;

      img {
        margin-top: 0.5rem;
      }

      p {
        font-weight: normal;
        font-size: 1.3rem;
        color: #161c2d;
        margin-bottom: 0.4rem;
      }
    }
  }

  &__bottomSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 2rem;
    gap: 1rem;

    h5 {
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #333333;
      white-space: nowrap;
    }

    &--btn {
      padding: 0.5rem 1rem;
      height: 2.8rem;
    }
  }
}
