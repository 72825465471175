.benefitsCards {
  display: grid;
  justify-content: center;
  /* grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); */
  grid-template-columns: repeat(4, 1fr);
  gap: 23px;
  padding: 0 5%;
}

@media screen and (max-width: 1240px) {
  .benefitsCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 880px) {
  .benefitsCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.benefitsCards-card {
  max-width: 293px;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px 20px;
  cursor: default;
}

.benefitsCards-card:hover {
  box-shadow: 0px 20px 20px rgba(50, 50, 71, 0.1),
    0px 32px 40px rgba(50, 50, 71, 0.1);
}

.benefitsCards-card > img {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.benefitsCards-card > h5 {
  margin-top: 25px;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #333333;
}

.benefitsCards-card > p {
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #7a7a7a;
  margin-top: 14px;
}

@media screen and (max-width: 600px) {
  .benefitsCards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .benefitsCards-card {
    max-width: 100%;
  }
}
