@import '../../../../../../../sass/main.scss';

.leaderboardTable {
  margin: 0;
  width: 100%;

  &.modal {
    width: 95%;
  }

  th,
  td {
    background-color: #fff;
    padding: 0;
    border: none;
    font-weight: 600;
    color: #999999;
  }

  th {
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: left;
    padding-bottom: 1rem;
  }

  td {
    padding: 1.3rem 0;
  }

  tr td:nth-child(even) {
    width: 80%;

    @include customMaxBreakPoint(500) {
      width: 90%;
    }
  }

  tr td:nth-child(odd) {
    width: 20%;
    @include customMaxBreakPoint(500) {
      width: 10%;
    }
  }

  td {
    font-weight: 600;
    font-size: 1.4rem;
    color: #333333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  &__td {
    &--namescope {
      display: flex;
      gap: 1.1rem;
      align-items: center;

      h5 {
        font-weight: 600;
        font-size: clamp(1.5rem, 4vw, 1.7rem) !important;
        color: #333333;
        text-transform: capitalize;
      }
      p {
        font-weight: 500;
        color: #999999;
        font-size: clamp(1.3rem, 4vw, 1.5rem) !important;
      }
    }
    &--attemptedQuestions {
      text-align: right;
      font-weight: 600;
      color: #333333;
      font-size: clamp(1.5rem, 4vw, 1.7rem) !important;
    }
  }
}
