.RecientQuizes {
  margin-top: 2rem;
  border-radius: 6px 6px 0px 0px;
  background: white;
  width: 100%;
  padding: 2rem;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &__body {
    margin-top: 3.6rem;
    display: grid;
    gap: 2.3rem;
  }
}
