.horizontal-line {
  width: 100%;
  height: 0.1rem;
  opacity: 0.08;
  background-color: #161c2d;
  box-sizing: border-box;

  &:last-child {
    display: none;
  }
}

.vertical-line {
  width: 0.1rem;
  background-color: #999999;
  box-sizing: border-box;
}
