.commentCard {
  display: flex;
  gap: 1.2rem;
  margin: 3rem 0;

  &__info {
    width: 100%;
    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4.5rem;

      h4 {
        font-weight: 600;
        font-size: 1.4rem;
        color: #111111;
      }

      p {
        font-weight: normal;
        font-size: 1.5rem;
        color: #333333;
      }
    }
    &--body {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #333333;
    }
    &--btn {
      margin-top: 1rem;

      span {
        font-size: 1.5rem;
        line-height: 2rem;
        color: #999999;
        margin-left: 0.6rem;
      }
    }
  }
}
