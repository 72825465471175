.resultSection {
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(50, 50, 71, 0.08);
  border-radius: 11px;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__flex {
    display: flex;
    gap: 2rem;
  }
}

.resultItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  gap: 1.1rem;

  img {
    width: 5rem;
    height: 5rem;
  }

  &__info {
    h4 {
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 2rem;
      letter-spacing: 0.001em;
      color: #111111;
      text-transform: capitalize;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #999999;
    }
  }
}
