@import '../../../sass/main.scss';

.socialLogin {
  width: 256px;

  &__buttons {
    width: 100%;
    h3 {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      font-style: normal;
      white-space: nowrap;
      margin-bottom: 2.4rem;
    }

    img {
      width: 100%;
      height: 4.2rem;
      cursor: pointer;
    }
  }

  @include customMaxBreakPoint(768) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__buttons {
      h3 {
        text-align: center;
      }
    }
  }
}
