.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
  > * {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: 0.001em;
    color: #4bbd97;
  }

  &__lineBreaker {
    color: rgba(0, 0, 0, 0.1);
  }

  &__path {
    &:last-child {
      color: #999999;
    }
  }
}
