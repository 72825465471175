.questions-container {
  &__wrapper {
    margin-bottom: 2rem;
    &.attend {
      background-color: rgba(219, 55, 55, 0.15);
    }
    &.unattend {
      background-color: rgba(217, 130, 43, 0.15);
    }
    &.correct {
      background-color: rgba(108, 228, 97, 0.15);
    }
  }
  &__correctAnswer {
    padding: 0 2rem;
    padding-bottom: 2rem;
    font-size: 1.8rem;
    color: #333333;
    letter-spacing: 0.02em;
    display: flex;
    gap: 3px;
    line-height: 2.2rem;
    font-weight: 600;
    align-items: center;

    small {
      color: green;
      font-size: 1.6rem;
    }

    h4 {
      white-space: nowrap;
      font-weight: 600;
    }

    div {
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-transform: capitalize;
      font-weight: 500;
      color: green !important;
      text-align: left !important;
      font-family: 'Poppins' !important;

      .MJX-TEX {
        font-weight: 500 !important;
        font-size: 1.8rem !important;
      }
    }
  }
}
