@import '../../../../sass/main.scss';

.profileHover {
  max-width: 40rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 1.6rem;

    &--image {
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--userInfo {
      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #161c2d;
        margin-bottom: 0.2rem;
        text-transform: capitalize;
      }

      h5 {
        font-weight: 300;
        font-size: 14px;
        color: #7a7a7a;
        margin-bottom: 0.6rem;
      }

      &-profile {
        display: flex;
        gap: 0.8rem;

        p {
          font-weight: 500;
          font-size: 14px;
          color: #4bbd97;
          text-transform: capitalize;
        }
      }
    }
  }

  &__links {
    margin: 0.8rem 0;
    cursor: pointer;

    p {
      padding: 0.6rem 1.6rem;
      text-transform: capitalize;
      font-size: 14px;
      color: #7a7a7a;
      text-transform: capitalize;
      font-size: 14px;
      color: #7a7a7a;
      transition: 350ms;
      &:hover {
        background-color: $color-grey-light-3;
      }
    }
  }

  &__logoutBtn {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    text-transform: capitalize;
    font-size: 14px;
    color: #7a7a7a;
    padding: 1.4rem 1.6rem;
    text-align: start;
    transition: 350ms;

    &:hover {
      background-color: $color-grey-light-3;
    }
  }
}
