.success-route {
  width: 100%;
  height: 15rem;
  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #3c763d;
    background: #ebf6f3;
    border-radius: 4px;
    padding: 1rem 1.2rem;
  }
}

.forgotPassword {
  display: grid;
  justify-self: center;
  justify-items: center;

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    color: #000000;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #333333;
    margin-bottom: 3rem;
  }
}

.forgotPasswordForm {
  width: 100%;
  &__input,
  &__btn {
    min-width: 100%;
  }

  &__btn {
    margin-top: 2rem;
  }
}
