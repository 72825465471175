.verify {
  width: 100%;
  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    color: #000000;
    margin-bottom: 2rem;
  }

  &__subtitle {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #7a7a7a;
  }

  &__email {
    text-decoration: underline;
    text-align: center;
    margin: 1rem 0;
    font-size: 1.6rem;
    background: #ebf6f3;
    padding: 1rem;
    border-radius: 4px;
    color: #3c763d;
  }

  &__resend-text {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: left;
    color: #000000;
  }
}
