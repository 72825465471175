@import '../../sass/main.scss';

.container-main {
  max-width: 124rem;
  margin: auto;

  @include customMaxBreakPoint(1350) {
    padding: 0 2rem;
  }
  @include customMaxBreakPoint(500) {
    padding: 0 1.6rem;
  }
}
