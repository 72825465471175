.circularProgress {
  display: flex;
  justify-content: center;
  width: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner--xs {
  width: 3rem;
}
.spinner--sm {
  width: 3.5rem;
}

.spinner--md {
  width: 4rem;
}

.spinner--lg {
  width: 5rem;
}
