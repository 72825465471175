@import '../../../sass/main.scss';

.footerPocketExam {
  margin-top: 8rem;
  margin-top: 20rem;

  &__imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    gap: 0.8rem;

    img {
      width: 12rem;
      height: 3.8rem;
    }
  }

  &__copyrightSection {
    text-align: center;
    font-weight: normal;
    font-size: 1.4rem;
    color: #7a7a7a;
    padding: 0 1.6rem;

    span {
      color: #333333;
    }
  }

  &__bottomSection {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    margin-top: 2.2rem;
    background-color: #fff;
    padding: 3rem 0;

    &--left {
      font-weight: normal;
      font-size: 1.4rem;
      color: #333333;

      ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        gap: 3.6rem;
        text-transform: capitalize;
        white-space: nowrap;
        flex-wrap: wrap;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      gap: 2rem;

      div {
        background: #f5f5f5;
        mix-blend-mode: normal;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 2.2rem;
        }
      }
    }

    @include customMaxBreakPoint(850) {
      display: flex;
      flex-direction: column;

      &--left {
        ul {
          display: flex;
          justify-content: center;
          gap: 2rem;
        }
      }
    }
  }
}
