// Colors

// White
$color-white-1: #fff;
$color-white-2: rgba(255, 255, 255, 0.5);
$color-white-3: #f3f4f6;
$color-white-4: #e5e7eb;

// Black
$color-black-1: #111;
$color-black-2: #111827;
$color-black-3: #374151;

// Light Black
$color-black-light-1: #c4cad6;

// Grey
$color-grey-1: #6b7280;
$color-grey-2: #18191f;
$color-grey-3: #9699a8;

// Light Grey
$color-grey-light: #9ca3af;
$color-grey-light-2: #d1d5db;
$color-grey-light-3: #f5f6fa;

// Blue
$color-blue-1: #0850a2;
$color-blue-2: #1558aa;

// blue light
$color-blue-light: #dbeafe;

// Cyan
$color-cyan-1: #4bbd97;
$color-cyan-2: #00badb;
$color-cyan-3: #06b6d4;

// Red
$color-red-1: #f43f5e;
$color-red-2: #ef4444;

// red light
$color-red-light: #fee2e2;

// Purple
$color-purple-1: #8b5cf6;

// green
$color-green-1: #10b981;
$color-green-2: #065f46;

// light green
$color-green-light: #d1fae5;
