@import '../../../../sass/main.scss';

.notificationHover {
  padding-bottom: 2rem;
  width: 30rem;

  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 0.001em;
    color: #232a31;
    padding: 2rem;
    padding-bottom: 1rem;
  }
  &__toggleBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      font-size: 1.6rem;
      color: #232a31;
      font-weight: 600;
      transition: 350ms;
      &:hover {
        background-color: $color-grey-light-3;
      }
      &.active {
        background-color: $color-grey-light-3;
      }
    }
  }
  &__body {
    max-height: 27rem;
    overflow: auto;
  }

  &__line {
    &:last-child {
      display: none;
    }
  }
}
