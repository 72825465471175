.pastTest {
  margin: 4rem 0;
  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 1.2rem;

    h4 {
      font-weight: 600;
      font-size: 2rem;
      letter-spacing: 0.001em;
      color: #111111;
      text-transform: capitalize;
    }
  }
}
