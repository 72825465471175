.box {
  margin: auto;
  max-width: 790px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  /* background: #ffffff;
  opacity: 1 !important;
  box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08),
    0px 24px 32px rgba(50, 50, 71, 0.08);
  border-radius: 10px; */
}
