@import '../../../sass/main.scss';

.sticky-timer {
  position: sticky;
  top: 8rem;
  height: 5rem;
  padding: 2rem;
  background-color: $color-cyan-1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  z-index: 1;
  p {
    color: #fff;
  }
  @include customMaxBreakPoint(600) {
    top: 6.6rem;
  }
}
