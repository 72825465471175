@import '../../../../../sass/main.scss';

.mockCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 2rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;

    &--intro {
      h4 {
        font-weight: 600;
        font-size: 1.7rem;
        letter-spacing: 0.001em;
        color: #4bbd97;
        margin-bottom: 0.4rem;
      }

      &-ratings {
        font-size: 1.5rem;
        color: #999999;
        span {
          font-weight: bold;
          color: #e7711b;
        }
      }
    }
  }

  &__body {
    h4,
    p {
      font-weight: 600;
      font-size: 1.6rem;
      color: #232a31;
      margin-bottom: 0.8rem;
      @include limitNoOfLines(2);
    }

    span {
      font-size: 1.4rem;
      letter-spacing: 0.001em;
      color: #999999;
    }
  }
}
