@import '../../../../../sass/main.scss';

.examDiscussionDetail {
  &__wrapper {
    width: 85%;
    margin-top: 2rem;
    margin-bottom: 3.4rem;

    @include customMaxBreakPoint(1024) {
      width: 100%;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.3rem;
    letter-spacing: 0.05em;
    color: #333333;
    margin-bottom: 2.7rem;
  }
}
