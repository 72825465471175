.questionContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2rem;

  &__title {
    h4 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      letter-spacing: 0.001em;
      color: #232a31;
    }

    p {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #999999;
    }
  }
}
.timer {
  display: flex;
  white-space: nowrap;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.001em;
  color: #232a31;

  &.sticky {
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #fff;
  }
}
