.enrollNowModal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;

    h3 {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #111827;
    }
  }

  &__body {
    display: grid;
    gap: 1.2rem;

    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #333333;
      }

      strong {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #111111;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    &--btn {
      font-size: 1.6rem;
      height: 4.4rem;
    }
  }
}
