.headerWithFiltering {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 2.4rem 0;

  h5 {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 0.001em;
    color: #111111;
  }

  &__flex {
    display: flex;
    gap: 1.4rem;

    &--select {
      height: 3.6rem;
      padding: 0.8rem 1.2rem;
    }

    &--btn {
      height: 3.6rem;
      padding: 0.6rem 2.1rem;
    }
  }
}
