@import '../../sass/main.scss';

.btn {
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1em 2em;
  white-space: nowrap;
  display: flex;
  justify-content: center;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &__main--contained {
    background: #4bbd97;
    border-radius: 4px;
    font-weight: normal;
    color: #ffffff;
    white-space: nowrap;
  }
  &__main--outlined {
    border: 1px solid #4bbd97;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    color: #4bbd97;
  }
  &__sub--outlined {
    border: 1px solid #9699a8;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    color: #9699a8;
  }
  &__gradient--contained {
    background: linear-gradient(90deg, #0fa6ac 0%, #4bbd97 100%);
    border-radius: 4px;
    font-weight: 600;
    color: #ffffff;
    text-transform: capitalize;
  }
}

.btn--sm {
  font-size: 1.2rem;
  line-height: 1.6rem;
  height: 2.6rem;
}

.btn--md {
  height: 4rem;
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.btn--lg {
  height: 4.4rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.btn--xl {
  height: 5rem;
  font-size: 1.6rem;
  line-height: 1.4rem;
}
