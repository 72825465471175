@import '../../../../../../sass/main.scss';

.newTopicModal {
  max-width: 124rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 3.4rem;
  width: 100%;
  position: relative;

  &__body {
    display: flex;
    gap: 3.4rem;
  }

  @include customMaxBreakPoint(768) {
    &__body {
      flex-direction: column;
    }

    &__cancelBtn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
