@import '../../../sass/main.scss';

.middlecontainer {
  width: 76%;
  transition: 350ms;
  z-index: 1;

  @include customMaxBreakPoint(1024) {
    width: 100%;
  }
}
