.registrationComplete {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 7rem 0;

  img {
    width: 4.4rem;
    height: 4.4rem;
  }

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: center;
    color: #3c763d;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}
