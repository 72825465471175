@import "../../../../../../../sass/main.scss";

.allnotification {
  height: 27rem;
  overflow: auto;
}

.notificationCard {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding: 1.6rem 2rem;
  transition: 350ms;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-light-3;
  }

  &__details {
    h5 {
      font-weight: 500;
      font-size: 1.4rem;
      color: #232a31;
      @include limitNoOfLines(2);
    }
    &--time {
      font-weight: normal;
      font-size: 1.4rem;
      letter-spacing: 0.0025em;
      color: #9299a1;
      margin-bottom: 0.6rem;
    }

    &--body {
      p {
        display: inline;
        color: #232a31;
        font-size: 1.2rem;
      }
      &-seeMoreBtn {
        color: #9299a1;
        display: inline;
        transition: 350ms;
        font-size: 1.2rem;
        font-weight: 600;
        &:hover {
          color: #232a31;
        }
      }
    }

    // &--image {
    //   width: 100%;
    //   margin-top: 1rem;
    //   img {
    //     width: 100%;
    //     aspect-ratio: 16/9;
    //   }
    // &:hover {
    //   transform: scale(1.5);
    // }
    // }
  }
}
