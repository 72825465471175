@mixin borderShadow {
  border-radius: 4px;
  box-shadow: -7px 9px 8px -6px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -7px 9px 8px -6px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -7px 9px 8px -6px rgba(0, 0, 0, 0.51);
}

@mixin footerListItem {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $color-grey-1;
}

@mixin grid-columns($width) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($width, 1fr));
}

@mixin imageContainer {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@mixin customMinBreakPoint($size) {
  @media screen and (min-width: $size+'px') {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media screen and (max-width: $size+'px') {
    @content;
  }
}

@mixin hideScrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin limitNoOfLines($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line; /* number of lines to show */
  -webkit-box-orient: vertical;
}
