@import '../../../sass/main.scss';

.headersidebar {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: -1;
  transition: 350ms;
  transform: translateX(-100vw);
  pointer-events: auto;
  &.active {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999999;
    opacity: 1;
    transform: translateX(0);
  }

  &__content {
    opacity: 0;
    display: flex;
    gap: 1rem;

    &.active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__body {
    width: 30rem;
    padding: 1rem;
    background-color: #fff;
    height: 100vh;

    &--linkItems {
      margin-top: 0.6rem;
      &-item {
        padding: 1rem 1.6rem;
        font-size: 1.6rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        text-transform: capitalize;
        color: #7a7a7a;
        cursor: pointer;
        transition: 350ms;

        &.active {
          color: $color-cyan-1;
        }

        &:hover {
          color: $color-cyan-1;
        }
        svg {
          font-size: 2rem;
        }
      }
    }
  }

  &__cancelIcon {
    cursor: pointer;
    transition: 350ms;
    background-color: #fff;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    &:hover {
      background-color: $color-grey-light-3;
    }

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
