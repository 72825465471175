.RecientQuizesCard {
  display: flex;
  gap: 1.6rem;
  &__image {
    width: 9rem;
    height: 9.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    h4 {
      font-weight: 600;
      font-size: 1.6rem;
      color: #232a31;
      margin-bottom: 0.2rem;
    }
    p {
      font-size: 1.4rem;
      color: #5b636a;
    }

    &--minusQuestions {
      font-size: 1.2rem;
      display: flex;
      gap: 0.5rem;
      margin-bottom: 1.2rem;
      p {
        color: #9299a1;
      }
    }
  }
}
