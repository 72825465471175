@import '../../../sass/main.scss';

.featuredCourseSection {
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4%;
    margin: 5rem 0;

    &--left {
      width: 50%;

      h1 {
        font-weight: 800;
        font-size: clamp(2.4rem, 5vw, 3rem);
        line-height: clamp(3rem, 5vw, 4rem);
        color: #111111;
        margin-bottom: 2rem;
      }

      p {
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: #7a7a7a;
      }

      &-listContainer {
        margin: 0.5rem 0 2rem 0;

        div {
          display: flex;
          gap: 1.7rem;

          p {
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #161c2d;
          }
        }
      }

      h4 {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: #111111;
      }
    }

    &--right {
      width: 50%;

      img {
        aspect-ratio: 16/9;
        width: 100%;
        // min-height: 32rem;
      }
    }

    @include customMaxBreakPoint(1024) {
      &--left {
        width: 40%;
      }

      &--right {
        width: 60%;
      }
    }

    @include customMaxBreakPoint(850) {
      flex-direction: column-reverse;
      gap: 1rem;
      margin-top: 3rem;
      &--left,
      &--right {
        width: 100%;
      }
      &--right {
        img {
          width: 100%;
          min-height: 20rem;
          object-fit: cover;
        }
      }
    }
  }
}
