@import '../../sass/main.scss';

.cancelButton {
  border: none !important;
  outline: none;
  cursor: pointer;
  font-size: 1.8rem;
  background-color: transparent;
  color: #9ca3af;
  transition: 350ms;
  &:hover {
    color: $color-black-1;
  }
}
