@import '../../../../../../../sass/main.scss';

.inputContainer {
  width: 50%;

  @include customMaxBreakPoint(1024) {
    width: 60%;
  }
  @include customMaxBreakPoint(768) {
    width: 100%;
  }
  header {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    font-size: 1.8rem;
    margin-bottom: 2.8rem;

    svg {
      color: #6b7280;
    }

    h4 {
      font-weight: 600;
      line-height: 2.2rem;
      color: #333333;
    }
  }

  &--body {
    display: grid;
    gap: 1rem;
  }

  &--flex {
    display: flex;
    gap: 1rem;
    width: 100%;

    &-select {
      width: 50%;
      font-weight: 500;
    }

    @include customMaxBreakPoint(500) {
      flex-direction: column;

      &-select {
        width: 100%;
      }
    }
  }

  &--btnGroups {
    margin-left: auto;
    display: flex;
    gap: 2.4rem;
    &-btn {
      text-transform: capitalize;
      font-size: 1.5rem;
      line-height: 2rem;
      // @include customMaxBreakPoint(500) {
      //   width: 100%;
      // }
    }
  }
}
