.form-box {
  display: flex;
  max-width: 76.8rem;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08),
    0px 24px 32px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
  padding: 3.5rem 4%;
  gap: 3.5rem;
}

@media screen and (max-width: 768px) {
  .form-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .form-box {
    padding: 3.5rem 2rem;
  }
}
