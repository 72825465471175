@import '../../../../sass/main.scss';

.questionQuizCard {
  padding: 2rem;

  &__question {
    margin-bottom: 1.6rem;

    &.null {
      margin-bottom: 0;
    }

    span {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #999999;
    }

    div {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #111111;

      b {
        font-weight: 800;
      }

      .MJX-TEX {
        font-weight: 600 !important;
        font-size: 1.8rem !important;
      }
    }

    img {
      max-height: 15rem;
      margin-top: 1rem;
      max-width: 100%;
    }
  }

  &__answers {
    display: grid;
    gap: 1rem;

    img {
      max-height: 15rem;
      max-width: 100%;
    }

    &--answer {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 1.2rem;
      transition: 350ms;
      cursor: pointer;

      &.active {
        border: 1px solid $color-cyan-1;
        background-color: $color-cyan-1;
        div {
          color: white !important;
        }
      }

      div {
        font-size: 1.6rem !important;
        // line-height: 2.2rem !important;
        color: #333333 !important;
        text-transform: capitalize !important;
        text-align: left !important;
        font-family: 'Poppins' !important;

        .MJX-TEX {
          font-weight: 500 !important;
          font-size: 1.6rem !important;
        }
      }

      &:hover {
        border: 1px solid #4bbd97;
      }
    }

    &--default {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 1.2rem;
      cursor: not-allowed;
      div {
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #333333;
        text-transform: capitalize;
        text-align: left;
      }
      &.correctAnswer {
        border: 1px solid $color-cyan-1;
        background-color: rgba(0, 128, 0, 0.7);
        div {
          color: white;
        }
      }
      &.wrongAnswer {
        border: 1px solid red;
        background-color: rgba(255, 0, 0, 0.7);
        div {
          color: white;
        }
      }
    }
  }
}
