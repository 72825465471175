.popper-menu {
  padding: 0;
  z-index: 999;
}

.popper-menu.search {
  margin-left: 4rem;
  margin-top: 2rem;
}

.popper-menu.cart {
  margin-left: 0;
  margin-top: 2.55rem;
}

.popper-menu.favorite {
  margin-top: 2.8rem;
}

.popper-menu.profile {
  margin-top: 1.5rem;
}

.popper-menu.notification {
  margin-top: 2.5rem;
  background-color: transparent;
}

.popper-menu.share {
  margin-top: 0.7rem;
}

.paper {
  background: #ffffff;
  border: 1px solid rgba(22, 28, 45, 0.08);
  box-shadow: 0px 1px 16px 3px rgba(50, 50, 71, 0.08);
  border-radius: 5px;
}
