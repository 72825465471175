.reviewAnswerPage {
  padding-top: 2.4rem;
  max-width: 80rem;
  margin: auto;

  &__body {
    margin-top: 1rem;
    background-color: #fff;
    background-color: transparent;
    padding-bottom: 2rem;
  }
}
