.dreamCourseCard {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 33.5rem;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 350ms;

  &:hover {
    box-shadow: 0px 20px 20px rgba(50, 50, 71, 0.1),
      0px 32px 40px rgba(50, 50, 71, 0.1);
  }

  img {
    width: 6.2rem;
    height: 6.3rem;
    margin-bottom: 2.4rem;
  }

  h4 {
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: #333333;
    text-transform: capitalize;
    margin-bottom: 1.4rem;
  }

  p {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.4rem;
    text-align: center;
    color: #7a7a7a;
  }
}
