@import '../../../sass/main.scss';

.reviewAnswerHeader {
  background-color: #fff;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
  }
  span {
    font-weight: 500;
  }
  &__col-1 {
    h1 {
      font-size: clamp(2.6rem, 5vw, 3rem);
    }
  }

  @include customMaxBreakPoint(550) {
    flex-direction: column;
    gap: 2rem;
  }
}
