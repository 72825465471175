.userProfile {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1.6rem;

  &__userInfo {
    h4 {
      font-weight: 500;
      font-size: 16px;
      color: #161c2d;
      margin-bottom: 0.2rem;
      text-transform: capitalize;
    }

    h5 {
      font-weight: 300;
      font-size: 14px;
      color: #7a7a7a;
      margin-bottom: 0.6rem;
    }

    &--profile {
      display: flex;
      gap: 0.8rem;

      p {
        font-weight: 500;
        font-size: 14px;
        color: #4bbd97;
        text-transform: capitalize;
      }
    }
  }
}
