.services {
  margin-top: 20px;
  border-radius: 6px;
  background: white;
  padding: 2rem;

  &__body {
    margin-top: 1.4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    gap: 1.1rem;

    &--card {
      width: 100%;
      text-align: center;

      img {
        width: 4rem;
        height: 4rem;
      }

      p {
        margin-top: 0.4rem;
        font-weight: normal;
        font-size: 1.4rem;
        letter-spacing: 0.004em;
        color: #464e56;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
  }
}
