.modalbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 99999999999999 !important;
  overflow: auto;
  border: none;

  &__body {
    overflow: auto;
    background-color: #fff;
    border: none;
    border-radius: 1rem;
    box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08),
      0px 24px 32px rgba(50, 50, 71, 0.08);
    padding: 2rem;
  }
}
