.courseExamFooter {
  background-color: #fff;
  position: sticky;
  bottom: 0;
  margin-top: 3rem;

  &__content {
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
