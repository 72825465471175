.discussionDetailHeader {
  margin-top: 4rem;
  margin-bottom: 2rem;

  h3 {
    font-weight: 600;
    font-size: 2rem;
    color: #111111;
    margin-bottom: 1rem;
  }

  p {
    font-weight: normal;
    font-size: 1.4rem;
    color: #4bbd97;
  }
}
