.freeClasses {
  background-color: #ffffff;
  padding: 2rem 2rem;
  margin-top: 2rem;
  border-radius: 6px;

  &__coursesflex {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 1em;
    margin-top: 1.4rem;
  }
}
