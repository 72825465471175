@import '../../sass/main.scss';

.header {
  position: fixed;
  max-height: 8rem;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #dfe0eb;
  background: #ffffff;
  padding: 1.6rem 0;
  z-index: 99999;
  transition: 350ms;

  &__hamburgerMenuIcon {
    cursor: pointer;
    svg {
      font-size: 2.5rem;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
  }

  &__logo {
    &--sm {
      font-size: 3rem;
    }

    &--lg {
      height: 5rem;
    }
  }

  &__left {
    display: flex;
    gap: 3rem;
    align-items: center;
    &--hamburgerMenu {
      display: none;
    }
  }

  &__right {
    display: flex;
    gap: 3rem;
    align-items: center;

    &--smallSearchIcon {
      display: none;
    }
  }

  @include customMaxBreakPoint(800) {
    &__left,
    &__right {
      gap: 2rem;
    }
  }

  @include customMaxBreakPoint(768) {
    &--left {
      &--hamburgerMenu {
        display: block;
      }
    }
    &__right {
      &--smallSearchIcon {
        display: block;
      }
    }
  }

  @include customMaxBreakPoint(650) {
    &__left,
    &__right {
      gap: 1.5rem;
    }
  }

  @include customMaxBreakPoint(390) {
    &__wrapper {
      gap: 1rem;
    }
  }
}
