.aboutusbanner {
  padding-top: 127px;
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #ebf6f3;
}

.aboutusbanner__img {
  z-index: 0;
}

.aboutusbanner__img-left {
  position: absolute;
  left: 120px;
  display: flex;
  flex-direction: column;
  row-gap: 65px;
}
.aboutusbanner__img-right {
  position: absolute;
  right: 120px;
  display: flex;
  flex-direction: column;
  row-gap: 65px;
}

.aboutusbanner__img-1 {
  width: 52px;
}
.aboutusbanner__img-2 {
  width: 35px;
  margin-left: 50px;
}
.aboutusbanner__img-3 {
  width: 54px;
}
.aboutusbanner__img-4 {
  width: 46px;
}
.aboutusbanner__img-5 {
  width: 36px;
  margin-left: -50px;
}
.aboutusbanner__img-6 {
  width: 38px;
}

.aboutusbanner__title {
  max-width: 487px;
  text-align: center;
  font-weight: normal;
  font-size: clamp(32px, 5vw, 36px);
  line-height: 44px;
  color: #333333;
  z-index: 1;
}

.aboutusbanner__title span {
  font-weight: 500;
  color: #20ad96;
}

.aboutusbanner__intro {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  color: #7a7a7a;
  text-align: center;
  max-width: 630px;
  margin: 24px 0 100px 0;
  z-index: 1;
}

.aboutusbanner__section {
  display: grid;
  column-gap: 125px;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
}

.aboutusbanner__section-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutusbanner__section-detail > h1 {
  font-weight: bold;
  font-size: 60px;
  font-size: clamp(40px, 5vw, 60px);
  line-height: 48px;
  color: #20ad96;
  margin-bottom: 13px;
  text-align: center;
}

.aboutusbanner__section-detail > p {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  color: #3f3a64;
  text-align: center;
}

@media screen and (max-width: 1080px) {
  .aboutusbanner {
    padding-left: 5%;
    padding-right: 5%;
  }
  .aboutusbanner__img-left {
    left: 20px;
  }
  .aboutusbanner__img-right {
    right: 20px;
  }
}

@media screen and (max-width: 900px) {
  .aboutusbanner__img {
    opacity: 0.6;
  }
}

@media screen and (max-width: 960px) {
  .aboutusbanner__section {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
  }
}
@media screen and (max-width: 700px) {
  .aboutusbanner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .aboutusbanner__section {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
}

@media screen and (max-width: 500px) {
  .aboutusbanner__section {
    display: grid;
    gap: 30px;
  }
}
