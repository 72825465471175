@import '../../../../sass/main.scss';

.questionAnalysis {
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 2rem;

  h4 {
    font-weight: 600;
    font-size: 18px;
    color: #111111;
    padding: 1.8rem 2rem;
    background: #f4f4f4;
  }

  &__list {
    padding: 2rem;
    gap: 1.6rem;
    max-height: 30rem;
    overflow-x: auto;
    @include grid-columns(4rem);

    div {
      width: 100%;
      height: 3.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f1f1f1;
      border-radius: 3px;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.8rem;
      color: #7a7a7a;
      transition: 0.5s;

      &.active {
        background-color: #4bbd97;
        color: #fff;
        // opacity: 0.7;
      }
    }
  }
}
