@import '../../../sass/main.scss';

.feedbackSection {
  padding: 2.4rem;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(50, 50, 71, 0.08);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 600;
    font-size: 1.6rem;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: center;
    color: #999999;
    margin-bottom: 2.7rem;
  }

  h5 {
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: center;
    color: #7a7a7a;
    margin: 2rem 0 2.4rem 0;
  }

  &__selectFeedback {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 2rem;

    button {
      background: #f4f4f4;
      border-radius: 120px;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #7a7a7a;
      padding: 0.6rem 1rem;
      transition: 300ms;
      white-space: nowrap;
      display: flex;
      justify-content: center;

      &:hover {
        color: #ffffff;
        background: #18a0fb;
      }
    }
    @include customMaxBreakPoint(450) {
      flex-direction: column;
      gap: 1rem;

      button {
        width: 100%;
      }
    }
  }

  &__textarea {
    background: #f7f7f7;
  }

  &__btnGroups {
    margin-top: 3rem;
    display: flex;
    gap: 2.4rem;
    align-items: center;

    @include customMaxBreakPoint(450) {
      flex-direction: column;
      gap: 1rem;
    }

    &--submit {
      background: linear-gradient(90deg, #0fa6ac 0%, #4bbd97 100%);
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    }
  }
}
